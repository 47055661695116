import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {TeamMembersCollection} from "@/classes/collections/TeamMembers.collection";
import {ApplicantsCollection} from "@/classes/collections/Applicants.collection";
import {TeamMemberModel} from "@/classes/models/TeamMember.model";
import {AccreditationApplicant} from "@/classes/models/AccreditationApplicant.model";

export class DtoApiPersonsRequest extends ApiRequestor {
    data;

    meta;

    $getPersons(params) {
        params = Object.assign({
            limit: 25,
            offset: 0,
        }, params);
        return this.$http('GET', 'persons', {params});
    }

    $toTeamMembersCollection() {
        const collection = new TeamMembersCollection();
        collection.models = this.data.map(item => {
            const model = new TeamMemberModel();
            [
                'firstName',
                'lastName',
                'birthDate',
                'gender',
                'phone',
                'email',
                'passportExpiryDate',
                'passportName',
                'passportNumber',
                'passportPhoto',
                'accreditationPhoto',
                'nationality',
                'documents',
            ].forEach(key => model[key] = item[key]);
            model.id = item.personId;
            model.organisation = item.organization.name;
            model.isDeletable = item.permissions.delete;
            model.isEditable = item.permissions.edit;
            model.categories = item.organization.categories.map(category => category.name).join(', ');
            return model;
        });
        return collection;
    }

    $toAccreditationApplicantsCollection() {
        const collection = new ApplicantsCollection();
        collection.models = this.data.map(person => {
            const applicant = new AccreditationApplicant();

            if (!!person.nationality) {
                applicant.nationality = {
                    id: person.nationality.countryId,
                    country: person.nationality.name
                }
            } else {
                applicant.nationality = null
            }

            applicant.name = `${person.firstName} ${person.lastName}`
            applicant.firstName = person.firstName
            applicant.lastName = person.lastName
            applicant.birthDate = person.birthDate
            applicant.email = person.email
            applicant.phone = person.phone
            applicant.gender = person.gender
            applicant.job = person.job
            applicant.id = person.personId
            applicant.organization = person.organization
            applicant.nationalityCountryId = person.nationalityCountryId
            applicant.passportName = person.passportName
            applicant.passportNumber = person.passportNumber
            applicant.passportExpiryDate = person.passportExpiryDate
            applicant.passportPhoto = person.passportPhoto
            applicant.passportPhotoId = person.passportPhotoId
            applicant.accreditationPhoto = person.accreditationPhoto
            applicant.accreditationPhotoId = person.accreditationPhotoId
            applicant.documents = person.documents

            applicant.invalidFields = []
            applicant.category = ''
            applicant.subCategory = ''
            applicant.venues = []
            applicant.accreditationType = ''
            applicant.activation = ''

            return applicant
        });
        return collection;
    }
}
