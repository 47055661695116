<template>
  <button
    class="button-component"
    :disabled="disabled"
    :class="{
      [form]: true,
      [type]: true,
      'disabled': disabled,
    }"
    @click="emmitClick"
  >
    <Icon
      v-if="icon"
      class="button-component__icon"
      :icon="Icons[icon]"
      :color="color"
      :style="{'margin-right': $slots.default ? '10px' : '0' }"
    />
    <slot />
  </button>
</template>

<script>
import Icon from '@/components/Icon/Icon.component';
import Icons from '@/components/Icon/Icon.list';

export default {
  name: 'DDMCAF-Button',
  components: {
    Icon
  },
  data: () => {
    return {
      Icons
    }
  },
  props: {
    color:{
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: { // left, right
      type: String,
    },
    form: { // round, rectangle
      type: String,
      default: 'rectangle'
    },
    type: { // primary, secondary, disabled, danger, success, link
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emmitClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.button-component {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  padding: 12px 16px;
  border: none;
  outline: none !important;

  /*Button form*/
  &.rectangle {
    height: 40px;
    border-radius: 12px;

    .button-component__image {
      max-width: 20px;
      max-height: 20px;
      position: relative;
      top: -1px;
      left: -2px;
    }
  }

  &.round {
    padding: 0;
    border-radius: 50%;
    position: relative;

    .button-component__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  /*Button form*/

  /*Button types*/
  &.primary {
    background: $secondary-color;
    color: #fff;
    .button-component__icon {
      background: #fff;
      color: $secondary-color;
    }
  }
  &.disabled {
    background: #9b9b9b ;
    color: #fff ;
    border-color: #9b9b9b ;
    .button-component__icon {
      background: #fff;
      color: #9b9b9b;
    }
  }
  &.secondary {
    border: 1px solid $secondary-color;
    background: none;
    color: $secondary-color;
    .button-component__icon {
      border: none;
      background: #fff;
      color: $secondary-color;
    }
  }
  &.danger {
    background: #f3371f;
    color: #fff;
    .button-component__icon {
      background: #fff !important;
      color: #f3371f !important;
    }
  }
  &.success {
    background: green;
    color: #fff;
    .button-component__icon {
      background: #fff !important;
      color: green !important;
    }
  }
  &.link {
    background: none;
    border: none;
    color: #000;
  }

  &.outline-primary {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;

    &.disabled {
      background-color: transparent;
      border-color: #9b9b9b;
      color: #9b9b9b;
    }
  }

  &.outline-danger {
    background-color: transparent;
    border: 1px solid $danger-color;
    color: $danger-color;

    &.disabled {
      background-color: transparent;
      border-color: #9b9b9b;
      color: #9b9b9b;
    }
  }

  &.outline-dark {
    background: #fff;
    border: 1px solid #c6cbd0;
    color: #000;

    &.disabled {
      color: #9b9b9b;
    }
  }
  /*Button types*/

  &.no-border {
    border-color: transparent !important;
  }
}
</style>
