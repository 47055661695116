<template>
  <section class="MyTeamList-View">
    <div class="MyTeamList-View__action-bar mb-3">
      <div class="MyTeamList-View__create-buttons">
        <Button
            v-if="$store.getters['user/permissions'].includes('persons:createAPI')"
            :style="{marginRight: '16px'}"
            img-url="button_img_dummy"
            @click.native="redirectToCreate()"
        >Create person
        </Button>
        <Button
            v-if="$store.getters['user/permissions'].includes('persons:importAPI')"
            img-url="button_img_dummy"
            @click.native="redirectToCreateBulk()">
          Bulk import persons
        </Button>
      </div>
      <div class="MyTeamList-View__search">
        <InputSearch placeholder="Search by First name, Last name, Nationality, Passport ID"
                     @searchkey="searchPersons" @clearFilters="searchPersons"/>
      </div>
    </div>

    <div :style="{marginBottom: '35px'}">
      <TeamTable :searchText="keyword"/>
    </div>
  </section>
</template>

<script>
import Section from '@/components/Section/Section.component';
import Button from '@/components/Button/Button.component';
import InputSearch from '@/components/Inputs/InputSearch.component';
import TeamTable from '@/components/Table/tables/TeamTable.component';
import RouterNames from "@/router/route.names";

export default {
  name: "MyTeamList-View",
  components: {
    Button,
    InputSearch,
    Section,
    TeamTable,
  },
  data: () => ({
    keyword: null,
  }),
  computed: {
    permissions() {
      return this.$store.getters['user/permissions']
    }
  },
  watch: {
    permissions() {
      this.checkPermissions()
    },
  },
  created() {
    this.checkPermissions();
  },
  methods: {
    redirectToCreate() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonCreate;
      this.$router.push({name});
    },
    redirectToCreateBulk() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonCreateBulk;
      this.$router.push({name});
    },
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToAccreditation'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    searchPersons(value) {
      this.keyword = value;
    }
  }
}
</script>
<style lang="scss">
.MyTeamList-View {
  &__action-bar {
    display: flex;
  }

  &__create-buttons {
    flex: 0 0 302px;
  }

  &__search {
    flex: 1 1 100%;
  }
}
</style>
