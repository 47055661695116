import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {
  IsBoolean,
  IsDate,
  IsEmail,
  IsInt,
  IsPhoneNumber,
  IsString,
  IsUrl,
} from "class-validator";
import {Fake} from "@/utils/faker.utils";
import {isArray} from "lodash";

export class TeamMemberModel extends ModelAbstraction {
  @Fake('random.number')
  @IsInt()
  id;

  @Fake('name.lastName')
  @IsString()
  lastName;

  @Fake('name.firstName')
  @IsString()
  firstName;

  @Fake('random.arrayElement', ['male', 'female'])
  @IsString()
  gender;

  @Fake('phone.phoneNumber', '+49177########')
  @IsPhoneNumber()
  phone;

  phoneCountry;

  @Fake('internet.email')
  @IsEmail()
  email;

  @Fake('date.future', 10)
  @IsDate()
  passportExpiryDate;

  @Fake('name.findName')
  @IsString()
  passportName;

  @Fake('internet.password', 12, false, /[0-9]/)
  @IsString()
  passportNumber;

  @Fake('image.imageUrl')
  @IsUrl()
  passportPhoto;

  @Fake('image.imageUrl')
  @IsUrl()
  accreditationPhoto;

  @Fake('date.between', new Date('1955-05-01'), new Date('1985-09-25'))
  @IsDate()
  birthDate;

  @IsString()
  nationality;

  @Fake('company.companyName')
  @IsString()
  organisation;

  @Fake('random.boolean')
  @IsBoolean()
  isDeletable;

  @Fake('random.boolean')
  @IsBoolean()
  isEditable;

  documents;

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  get photoName() {
    return `${this.firstName}_${this.lastName}`;
  }

  get passportPhotoName() {
    return `${this.firstName}_${this.lastName}_ID`;
  }
}
