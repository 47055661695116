<template>
  <div class="Image__content" :class="imageClass">
    <div class="Image__content-image" v-if="image">
      <img
          :src="resized(60)"
          :alt="alt"
          @click="openModal"
      />
    </div>
    <b-button v-if="!image"
        variant="outline-danger"
        @click="redirectToEdit(value)">
      Photo missing
    </b-button>
    <ThumbnailPopup
        v-if="showPopup"
        :image="image"
        :popupTitle="title"
        @closePopup="onClose"
    />
  </div>
</template>

<script>
import ThumbnailPopup from '@/components/Popup/ThumbnailPopup.component';
import RouterNames from "@/router/route.names";

export default {
  name: 'DDMCAF-Thumbnail',
  components: {ThumbnailPopup},
  props: {
    value: Object,
    type: String,
    title: String,
  },
  data: () => ({ image: null, imageClass: '', showPopup: false, alt: '', title:''}),

  created() {
    this.setImage();
  },
  watch: {
    value(){
      this.setImage();
    },
  },
  methods: {
    setImage(){
      this.imageClass = this.type;
      this.image = this.value[this.type];
      this.alt = this.value.passportName;
    },
    resized(width, height) {
      const url = new URL(this.image);
      if (width) {
        url.searchParams.append('width', width);
      }
      if (height) {
        url.searchParams.append('height', height);
      }
      return url.href;
    },
    openModal() {
      this.showPopup = true;
    },
    onClose() {
      this.showPopup = false;
    },
    redirectToEdit(person) {
      const personId = person.id;
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonEdit;
      const params = {personId};
      this.$router.push({name, params});
    },
  }
}
</script>

<style lang="scss">
.Image__content-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  > img {
    width: 100%;
    cursor: pointer;
  }
}
.Image__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .btn {
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 16px;
  }
}
</style>
