<template>
  <div class="ThumbnailPopup">
    <div class="ThumbnailPopup__window">
      <div class="ThumbnailPopup__close-popup">
        <span @click="closePopup">X</span>
      </div>
      <div class="ThumbnailPopup__title">
        {{ this.popupTitle }}
      </div>
      <div class="ThumbnailPopup__content">
        <img :src="popupImage" width="480"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DDMCAF-ThumbnailPopup",
  props: {
    image: String,
    popupTitle: String
  },
  data: () => ({
    popupImage: ''
  }),
  methods: {
    closePopup() {
      this.$emit('closePopup')
    },
  },
  created() {
    this.popupImage= this.image
  },
}
</script>

<style lang="scss">
.ThumbnailPopup {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 100;
  padding: 20px;
  overflow: scroll;
  &__window {
    width: 750px;
    padding: 20px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    width: auto;
    max-height: 750px;
    overflow: hidden;
  }

  &__title {
    font-size: 18px;
    margin: 0 0 10px;
  }

  &__close-popup {
    width: 30px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    right: 20px;
    font-size: 20px;

    span {
      cursor: pointer;
    }
  }

}
</style>
