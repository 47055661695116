<template>
  <section class="TeamTable" :style="{minWidth: '800px'}">
    <div class="row">
      <div class="col-md-4 mb-3">
        <Button class="button-delete-bulk" type="outline-dark"
                :disabled="this.selectedRows.length === 0"
                @click="requestBulkDelete()"
                icon="BIN_OUTLINE"> Delete persons
        </Button>
      </div>
    </div>

    <Table
        :collection="collection"
        :columns="tableColumns"
        :details-index="detailsIndex"
        @on-sort="onSort"
    >
      <template v-slot:table-cell-email="slot">
        <span class="TeamTable__email with-word-break">
          {{ slot.data.toLowerCase() }}
        </span>
      </template>
      <template v-slot:table-cell-birthDate="slot">
        {{ slot.data ? moment(slot.data).format('DD.MM.YYYY') : '--' }}
      </template>
      <template v-slot:table-details="slot">
        <div class="TeamTable__details">
          <div class="TeamTable__details-row">
            <div class="TeamTable__details-cell">
              <div class="TeamTable__details-cell-header">Personal Information</div>
              <div class="TeamTable__details-cell-content">
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">First Name (as in passport)</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.firstName }}</div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Last Name (as in passport)</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.lastName }}</div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Gender</div>
                  <div :class="[
                    'TeamTable__details-cell-content-row-value',
                    'TeamTable__details-cell-content-row-value_capitalized'
                  ]">{{ slot.model.gender }}
                  </div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Date of Birth</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ moment(slot.model.birthDate).format('DD.MM.YYYY') }}</div>
                </div>
              </div>
            </div>
            <div class="TeamTable__details-cell">
              <div class="TeamTable__details-cell-header">Organisation Information</div>
              <div class="TeamTable__details-cell-content">
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Name</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.organisation }}</div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Category</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.categories }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="TeamTable__details-row">
            <div class="TeamTable__details-cell">
              <div class="TeamTable__details-cell-header">Contact Information</div>
              <div class="TeamTable__details-cell-content">
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Email</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.email.toLowerCase() }}</div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Mobile</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.phone }}</div>
                </div>
              </div>
            </div>
            <div class="TeamTable__details-cell">
              <div class="TeamTable__details-cell-header">Identification Information</div>
              <div class="TeamTable__details-cell-content">
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Nationality</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.nationality ? slot.model.nationality.title : '--' }}</div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Passport / ID Number</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.passportNumber }}</div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Passport / ID Expiry Date</div>
                  <div class="TeamTable__details-cell-content-row-value">{{ slot.model.passportExpiryDate ? moment(slot.model.passportExpiryDate).format('DD.MM.YYYY') : '--' }}</div>
                </div>

                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Accreditation Photo</div>
                  <div :class="[
                    'TeamTable__details-cell-content-row-value',
                    'TeamTable__details-cell-content-row-value_photo'
                  ]">
                    {{ slot.model.photoName }}
                    <img :src="$static.icon.magnifying_glass" alt="" :style="{height: '10px', width: '10px', marginLeft: '10px'}">
                  </div>
                </div>
                <div class="TeamTable__details-cell-content-row">
                  <div class="TeamTable__details-cell-content-row-title">Copy of Passport / ID</div>
                  <div :class="[
                    'TeamTable__details-cell-content-row-value',
                    'TeamTable__details-cell-content-row-value_photo'
                  ]">
                    {{ slot.model.passportPhotoName }}
                    <img :src="$static.icon.magnifying_glass" alt="" :style="{height: '10px', width: '10px', marginLeft: '10px'}">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[
            'TeamTable__details-row',
            'TeamTable__details-row_reversed'
          ]">
            <Button
                class="TeamTable__details-button"
                type="secondary"
                icon="EDIT_OUTLINE"
                @click="redirectToEdit(slot.model)"
            >
              Edit Person
            </Button>
          </div>
        </div>
      </template>
      <template v-slot:table-cell-buttons="slot">
        <Button
            class="TeamTable__button-delete"
            @click="requestDelete(slot.model)"
            icon="BIN_OUTLINE"
            form="round" type="outline-danger no-border"
            v-if="slot.model.isDeletable"
        />
        <Button
            @click="switchDetails(slot.index)"
            :icon="(slot.index === detailsIndex) ? 'UP_OUTLINE' : 'DOWN_OUTLINE'"
            form="round" type="outline-primary no-border"
        />
      </template>
      <template v-slot:table-cell-passportPhoto="slot">
        <Thumbnail :value="slot.model"
                   type="passportPhoto"
                   v-bind:title="`Passport Photo - ` +  slot.model.firstName +' ' + slot.model.lastName " />
      </template>
      <template v-slot:table-cell-accreditationPhoto="slot" >
        <Thumbnail :value="slot.model"
                   type="accreditationPhoto"
                   v-bind:title="`Accreditation Photo -` + slot.model.firstName +' ' + slot.model.lastName "  />
      </template>
      <template v-slot:table-cell-rowSelect="slot">
        <input type="checkbox" class="TeamTable__checkbox-select rowSelect"  v-model="selectedRows" :value="slot.model.id" />
      </template>
      <template v-slot:table-header-bulk="slot">
        <input type="checkbox" class="checkAll" v-model="checkAll" />
      </template>

    </Table>
    <TableControls @setLimit="updateLimit($event)" @selectPage="updatePage($event)"
                   :count="request.meta ? request.meta.count : 0"
                   :offset="request.meta ? request.meta.offset : 0"
                   :limit="limit" />

    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
  </section>
</template>

<script>
import moment from 'moment';
import toLower from 'lodash.lowercase';
import set from 'lodash.set';
import get from "lodash.get";
import magnifying_glass from '@/assets/DDMCAF/images/magnifying_glass_icon.svg';
import Table from "@/components/Table/Table.component";
import TableControls from "@/components/Table/TableControls.component";
import Button from "@/components/Button/Button.component";
import Thumbnail from "@/components/Image/Thumbnail.component";
import {TeamMembersCollection} from "@/classes/collections/TeamMembers.collection";
import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";
import {DtoApiPersonsRequest} from "@/classes/dto/api/person/dto.api.persons.request";
import RouterNames from "@/router/route.names";
import {DtoApiDeletePersonRequest} from "@/classes/dto/api/person/dto.api.delete-person.request";

const defaultLimit = 25;

export default {
  name: 'DDMCAF-TeamTable',
  components: {Table, TableControls, Button, Thumbnail},
  props: {
    searchText: String
  },
  data: () => ({
    request: new DtoApiPersonsRequest(),
    collection: new TeamMembersCollection(),
    detailsIndex: null,
    limit: defaultLimit,
    page: 1,
    pageMemo: null,
    pages: 1,
    tableColumns: [],
    selectedRows: [],
  }),
  computed: {
    checkAll: {
      get: function () {
        return this.selectedRows.length > 0;
      },
      set: function (value) {
        var checked = [];
        if (value) {
          this.collection.models.forEach(function (model) {
            checked.push(model.id);
          });
        }
        this.selectedRows = checked;
      }
    },
    account() {
      return get(this, '$store.state.user.account', []);
    },
    pageComputed() {
      return this.request.$loading
          ? this.pageMemo
          : this.page;
    }
  },
  created() {
    set(this, '$static.icon.magnifying_glass', magnifying_glass);

    this.setTableColumnsBasedOnWidth(window.outerWidth)

    window.addEventListener("resize", this.screenResizeHandler);

    this.page = parseInt(this.$route.query.page) || this.page;
    this.limit = parseInt(this.$route.query.limit) || this.limit;

    this.getCollection();
  },
  watch: {
    account({id}) {
      this.pageMemo = this.page;
      this.page = parseInt(this.$route.query.page) || 1;
      this.getCollection(id);
    },
    searchText(text){
      this.getCollection(this.$store.state.user.account.id, text);
    }
  },
  methods: {
    toLower,
    moment,
    async updatePage(page) {
      this.pageMemo = page;
      this.page = page;
      this.$router.replace({
        query: {
          page: this.page > 1 ? this.page : undefined,
          limit: this.limit !== defaultLimit ? this.limit : undefined
        }
      });
      await this.getCollection();
    },
    async updateLimit(limit) {
      this.pageMemo = this.page;
      this.page = 1;
      this.limit = limit;
      this.$router.replace({
        query: {
          limit: this.limit !== defaultLimit ? this.limit : undefined
        }
      });
      await this.getCollection();
    },
    async getCollection(account = this.$store.state.user.account.id, searchText = '') {
      const offset = (this.page - 1) * this.limit;
      const {limit} = this;
      this.request = await this.request.$getPersons({
        organizationId: account,
        limit,
        offset,
        searchText,
        orderBy: this.orderBy,
        orderByDirection: this.orderByDirection,
      });
      this.detailsIndex = null;
      this.pages = Math.ceil(this.request.meta.count / this.limit);
      const collection = this.request.$toTeamMembersCollection();
      if (!collection.models.length && this.page !== 1) {
        await this.updatePage(Math.max(Math.min(this.page, this.pages), 1));
      } else this.collection = this.request.$toTeamMembersCollection();
    },
    async requestDelete({id}) {
      this.$confirm(
          {
            message: `Are you sure you want to delete this person?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeletePersonRequest().$deletePerson([id], this.$store.state.user.account.id).then(() => {
                  this.getCollection()
                })
              }
            }
          })

    },
    async requestBulkDelete()
    {
      this.$confirm(
          {
            message: `Are you sure you want to delete selected persons?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {

                new DtoApiDeletePersonRequest().$deletePerson(this.$data.selectedRows, this.$store.state.user.account.id).then(() => {
                  this.selectedRows = [];
                  this.getCollection()
                })
              }
            }
          })
    },
    switchDetails(index) {
      if (index === this.detailsIndex) return this.detailsIndex = null;
      this.detailsIndex = index;
    },
    redirectToEdit(person) {
      const personId = person.id;
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonEdit;
      const params = {personId};
      this.$router.push({name, params});
    },
    screenResizeHandler(e) {
      this.setTableColumnsBasedOnWidth(e.target.outerWidth)
    },
    setTableColumnsBasedOnWidth(width) {
      let models = [
        new TableColumnModel('rowSelect', '', '10px'),
        new TableColumnModel('firstName', 'First Name', '25px','firstName',''),
        new TableColumnModel('lastName', 'Last Name', '30px', 'lastName',''),
      ]

      if (width > 600) {
        models.push( new TableColumnModel('nationality.name', 'Nationality', '20px', 'nationality.name', ''));
        models.push(new TableColumnModel('gender', 'Gender', '20px', 'gender',''));
      }
      if (width > 700) {
        models.push(new TableColumnModel('birthDate', 'Date of Birth', '30px', 'birthDate', ''));
      }
      if (width > 800) {
        models.push(new TableColumnModel('passportNumber', 'Passport/ID NO.', '25px', 'passportNumber', ''));
      }
      if (width > 900) {
        models.push(new TableColumnModel('accreditationPhoto', 'Accreditation Photo', '30px'))
        models.push(new TableColumnModel('passportPhoto', 'Passport/ID Photo', '30px'))

      }

      models.push(new TableColumnModel('buttons', '', '15px'));

      this.tableColumns = new TableColumnsCollection(models);
    },
    onSort(event) {
      this.orderBy = event.sort;
      this.orderByDirection = event.sortDirection;
      this.getCollection();
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";


.confirm-dialog {
  .vc-btn:last-child {
    color: $danger-color;
  }

  .vc-btn:first-child {
    color: $primary-color;
  }
}

.Table__table-cell_buttons {
  max-width: 50px;

  .button-component:first-child {
    margin-right: 5px;
  }
}

.Table__table-cell_buttons .Table__table-cell-container {
  display: flex;
  justify-content: space-between;
}

.TeamTable {
  .button-component:not(.TeamTable__details-button) {
    background-color: white
  }

  .TeamTable__details-button {
    display: flex;
    flex-direction: row-reverse;

    i {
      font-size: 18px !important;
      margin-left: 8px;
    }
  }

  .Table__table-row_data {
    border-bottom: none
  }

  .Table__table-cell-content, .TeamTable__email {
    font-size: 12px;
    font-weight: 600;
  }

  .Table__table-cell-content_name {
    font-weight: bold;
  }

  .button-component__icon {
    font-size: 28px !important;
  }

  &__details {
    background-color: #F8F8F8;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    max-width: 100%;

    div {
      box-sizing: border-box;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:not(:first-of-type) {
        margin-top: 24px;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #D8DAE3;
      }

      &_reversed {
        display: flex;
        justify-content: flex-end;
      }
    }

    &-cell {
      &-header {
        color: $primary-color;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
      }

      &-content {
        width: 400px;
        padding: 24px 0;

        &-row {
          display: flex;

          &:not(:last-of-type) {
            padding-bottom: 18px;
          }

          &-title {
            width: 160px;
            max-width: 160px;
            margin-right: 10px;

            height: 14px;
            color: #AFB1BC;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 14px;
          }

          &-value {
            display: flex;
            align-items: center;
            width: 175px;
            color: #000000;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 17px;

            &_photo {
              color: $primary-color;
            }

            &_capitalized {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
</style>
