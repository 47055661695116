import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {IsInstance, ValidateNested} from "class-validator"
import {TeamMemberModel} from "@/classes/models/TeamMember.model";

export class TeamMembersCollection extends CollectionAbstraction {
  @IsInstance(TeamMemberModel)
  @ValidateNested()
  models;

  constructor(models = []) {
    super(models);
    this.models = [...models];
  }

  async getFake(length = 10) {
    const model = new TeamMemberModel();
    return await super.getFake(model, length);
  }
}
