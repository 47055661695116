<template>
  <table class="input-search">
    <tr>
      <td class="input-search-wrapper">
        <input :placeholder="placeholder" v-model="searchKey"
               @keyup.enter="searchContent">
        <Button
            class="button-component rectangle primary"
            @click="searchContent">
          Search
        </Button>
      </td>
      <td class="input-search-button">
        <Button
            type="link"
            @click="clearFilters">
          Clear filters
        </Button>
      </td>
    </tr>
  </table>
</template>

<script>
import Button from '@/components/Button/Button.component';

export default {
  name: 'DDMCAF-InputSearch',
  components: {Button},
  props: {
    placeholder: String,
  },
  data: () => ({searchKey: null}),
  methods: {
    clearFilters() {
        this.searchKey = ''
        this.$emit('clearFilters')
    },
    searchContent() {
      this.$emit('searchkey', this.searchKey)
    },
  }
}
</script>

<style lang="scss">
.input-search {
  table-layout: fixed;
  width: 100%;

  .input-search-wrapper {
    width: calc(100% - 105px);
    font-weight: 700;

    input {
      padding: 1px 16px 0;
      width: calc(100% - 88px);
      font-size: 12px;
      line-height: 37px;
      border-radius: 12px;
      border: 1px solid #D8DAE3;
      font-weight: 700;
      color: #383838;
      margin-right: 15px;
    }
  }
  .input-search-button {
    width: 105px;
  }
}

</style>
